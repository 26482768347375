import { environment } from './environments/environment';

/**
 * @deprecated at auth module config (const.ts)
 */
export const API_VERSIONs = {
  VERSION_1_0_0: '1.0.0',
  VERSION_2_0_0: '2.0.0',
};

export const API_ENDPOINTS = {
  // tài chính - thu học phí
  thuHocPhi: `${environment.domain.apiTaiChinhThuHocPhiNhapHoc}/thu-hoc-phi`,
  dataBanDau: `${environment.domain.apiTaiChinhThuHocPhiNhapHoc}/data-ban-dau`,  
  bankTransferError: `${environment.domain.apiTaiChinhThuHocPhiNhapHoc}/bank-transfer-error`,
  xuLyTrongNgay: `${environment.domain.apiTaiChinhThuHocPhiNhapHoc}/thu-hoc-phi/phieu-thu/xu-ly-trong-ngay`,
  confirmPaymentViaBank: `${environment.domain.apiTaiChinhThuHocPhiNhapHoc}/thu-hoc-phi/phieu-thu/xac-nhan-ngan-hang`,
  collectMoney: `${environment.domain.apiTaiChinhThuHocPhiNhapHoc}/thu-hoc-phi/phieu-thu/dong-hoc-phi`,
  changeMajor: `${environment.domain.apiTaiChinhThuHocPhiNhapHoc}/thu-hoc-phi/phieu-thu/chuyen-nganh`,
  refund: `${environment.domain.apiTaiChinhThuHocPhiNhapHoc}/thu-hoc-phi/phieu-thu/rut-hoc-phi`,
  downloadPdfThongKe: `${environment.domain.apiTaiChinhThuHocPhiNhapHoc}/thu-hoc-phi/phieu-thu/thong-ke`,
  filev2: `${environment.domain.apiTaiChinhThuHocPhiNhapHoc}/file-v2`,
  log: `${environment.domain.apiTaiChinhThuHocPhiNhapHoc}/log`,
  setting: `${environment.domain.apiTaiChinhThuHocPhiNhapHoc}/setting`,
};
